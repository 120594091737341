
import './App.css';
import EmailBatch from './component/EmailBatch';
import Sidebar from './component/Sidebar';

function App() {
  return (
    <div className="App">
      <div class="d-flex">
      {/* <Sidebar/> */}
     <EmailBatch/>
     </div>
    </div>
  );
}

export default App;
